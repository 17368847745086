var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.title,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
          },
          on: {
            hidden: function ($event) {
              return _vm.hidden(null)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _vm.allowSelect
                    ? [
                        _vm.canEdit() || !_vm.exists
                          ? _c(
                              "b-button",
                              {
                                attrs: { size: "sm", variant: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.ok(false)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("button.ok")))]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "SELECT" === _vm.mode
                              ? "button.close"
                              : "button.cancel"
                          )
                        )
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              on: { dismissed: _vm.dismissAlert },
              model: {
                value: _vm.errorShow,
                callback: function ($$v) {
                  _vm.errorShow = $$v
                },
                expression: "errorShow",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c("b-form-radio-group", {
                    staticClass: "preview-state-toggler",
                    attrs: {
                      options: [
                        { text: _vm.$t("comment.button.write"), value: false },
                        { text: _vm.$t("comment.button.preview"), value: true },
                      ],
                      buttons: "",
                      "button-variant": "outline-secondary",
                      size: "sm",
                    },
                    model: {
                      value: _vm.preview,
                      callback: function ($$v) {
                        _vm.preview = $$v
                      },
                      expression: "preview",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "pl-md-0",
                  attrs: { cols: "12", "offset-md": "4", md: "6" },
                },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mt-2 mt-md-0",
                      attrs: {
                        label: _vm.$t("field.identifier"),
                        "label-for": "identifier",
                        "label-align-md": "right",
                        "label-cols-md": "3",
                        "content-cols-md": "9",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "identifier",
                              type: "text",
                              "data-vv-as": _vm.$t("field.identifier"),
                              "data-vv-name": "comment.identifier",
                              maxlength: _vm.maxIdentifierLength,
                              disabled: _vm.isReadOnly,
                              size: "",
                              trim: "",
                            },
                            model: {
                              value: _vm.comment.identifier,
                              callback: function ($$v) {
                                _vm.$set(_vm.comment, "identifier", $$v)
                              },
                              expression: "comment.identifier",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-textarea", {
            ref: "textarea",
            staticClass: "comment-textarea rounded-0",
            class: { "d-none": _vm.previewState },
            attrs: {
              refs: "commentInput",
              name: "note",
              id: "message",
              rows: "9",
              placeholder: _vm.$t("comment.placeholder.your_comment"),
              trim: "",
              required: "",
              autofocus: "",
            },
            model: {
              value: _vm.comment.text,
              callback: function ($$v) {
                _vm.$set(_vm.comment, "text", $$v)
              },
              expression: "comment.text",
            },
          }),
          _c("div", {
            staticClass: "preview markdown-body",
            class: { "d-none": !_vm.previewState },
            domProps: { innerHTML: _vm._s(_vm.compiledMarkdown) },
          }),
          _c(
            "div",
            { staticClass: "markdown-hint" },
            [
              _c(
                "font-awesome-layers",
                { staticClass: "fa-lg info-icon" },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle"], transform: "shrink-2" },
                  }),
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "info"], transform: "shrink-8" },
                  }),
                ],
                1
              ),
              _c("span", [
                _c(
                  "a",
                  {
                    attrs: {
                      target: "_blank",
                      href: "https://projectal.com/resources/markdown",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("comment.link.markdown")))]
                ),
                _vm._v(" " + _vm._s(_vm.$t("comment.link.is_supported"))),
              ]),
            ],
            1
          ),
          _vm.showList && _vm.canList("NOTE")
            ? _c("CommentList", {
                staticClass: "mt-2",
                attrs: {
                  id: _vm.holderid,
                  isDialog: true,
                  comments: _vm.comments,
                },
                on: {
                  success: _vm.commentSuccess,
                  add: _vm.add,
                  edit: _vm.edit,
                  error: _vm.commentError,
                  reload: _vm.commentSuccess,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task.confirmation.save_comment"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              _vm.promptSaveShow = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmSaveOk(true)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.yes")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmSaveOk(false)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.no")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.promptSaveShow,
            callback: function ($$v) {
              _vm.promptSaveShow = $$v
            },
            expression: "promptSaveShow",
          },
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("comment.save_prompt")))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }