<template>
  <div>
    <b-modal v-model="modalShow" size="lg" :title="title"
      footer-class="footerClass"
      @hidden="$emit('update:show', false)"
      no-close-on-backdrop  content-class="shadow" modal-class="anti-shift" body-class="chart-modal-body"
      :ok-title="$t('button.select')"
      scrollable
    >
      <b-alert :variant="alertError? 'danger':'success'" dismissible :show="showError" @dismissed="dismissAlert">
        <font-awesome-icon :icon="alertError? ['fas', 'triangle-exclamation'] : ['far', 'check']"/>&nbsp;&nbsp;{{ alertMsg }}
      </b-alert>
      
      <b-form-group :label="$t('dataview.field.name')" label-for="name" :class="{ 'mb-0': showNameError }">
        <b-input-group>
          <b-form-input id="name" type="text"
            data-vv-as="Name"
            data-vv-name="chart.name"
            data-vv-delay="500"
            v-model="chart.name" 
            :state="fieldValidateUtil.stateValidate(false, veeFields, errors, 'chart.name')"
            autofocus trim >
          </b-form-input>
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('chart.name') }}
        </b-form-invalid-feedback>
      </b-form-group>
           
     <b-form-checkbox name="display_as_title" class="w-auto mb-3" v-model="chart.display_title">{{ $t('dataview.chart.display_as_title') }}</b-form-checkbox>
            
      <b-form-group :label="$t('dataview.field.description')" label-for="dataview-description">
        <b-form-textarea id="dataview-description" 
          :placeholder="$t('dataview.placeholder.description')"
          :data-vv-as="$t('dataview.field.description')"
          data-vv-name="chart.description"
          data-vv-delay="500"
          v-model="chart.description"
          :max-rows="6"
          :rows="3"/>
      </b-form-group>
      
      <div class="d-block">
        
        <div class="mt-1" v-if="supportsMultipleSeries">
          <label for="addseries">{{ $t('dataview.chart.series') }}</label>
          <button name="addseries" class="btn-action" @click="addSeries"><font-awesome-icon :icon="['far', 'plus']"/></button>
        </div>
        <div class="chart-series">
          <div v-bind:key="seriesIndex" class="series-container" v-for="(key, seriesIndex) in chart.series">  
            <button v-if="supportsMultipleSeries" class="series-remove-btn" @click="removeSeries(seriesIndex)"><font-awesome-icon :icon="['far', 'trash-can']"/></button>
            
            <b-row>
              <b-col>
                <label class="d-block" for="chartType">{{ $t('dataview.chart.type') }}</label>
                <multiselect v-model="chart.series[seriesIndex].type" class="custom-dropdown-options enable-option-icon fit-label-fix fit-content-fix"
                  :class="{ 'fix-content-position': enablePositionFixed }"
                  :max-height="enablePositionFixed? 300 : 150"
                  :options="getChartTypes(seriesIndex).map(i => i.value)"
                  :custom-label="getChartTypeOptionLabel(seriesIndex)"
                  :placeholder="''"
                  :searchable="false" 
                  :allow-empty="false"
                  :showLabels="false"
                  @input="changeType(seriesIndex)">
                  <template slot="option" slot-scope="props">
                    <font-awesome-icon class="selected-option-icon" v-if="chart.series[seriesIndex].type == props.option" :icon="['far', 'check']" />
                    <span class="option__title">{{ getChartTypeOptionLabel(seriesIndex)(props.option) }}</span>
                  </template>
                </multiselect>
              </b-col>
              <b-col>    
                <div>
                  <label class="d-block" for="legendposition">{{ $t('dataview.chart.legend') }}</label>
                  <multiselect v-model="chartLegendPositionStr" class="custom-dropdown-options enable-option-icon fit-label-fix fit-content-fix"
                    :class="{ 'fix-content-position': enablePositionFixed }"
                    :max-height="enablePositionFixed? 300 : 150"
                    :options="legendPositions.map(i => i.value)"
                    :custom-label="getLegendPositionOptionLabel"
                    :placeholder="$t('dataview.legend.none')"
                    :searchable="false" 
                    :allow-empty="true"
                    :showLabels="false"
                    @input="changeLegendPosition">
                    <template slot="option" slot-scope="props">
                      <font-awesome-icon class="selected-option-icon" v-if="chartLegendPositionStr == props.option" :icon="['far', 'check']" />
                      <span class="option__title">{{ getLegendPositionOptionLabel(props.option) }}</span>
                    </template>
                  </multiselect>
                </div>
              </b-col>
            </b-row>
                
            <b-form-checkbox name="fit" class="w-auto mb-3 fit-checkbox" v-model="chart.fit">{{ $t('dataview.chart.fit') }}</b-form-checkbox>
            
                 
            <template v-if="isPie(seriesIndex)">
              <div class="mt-2">
                <label class="d-block" for="labelkey">{{ $t('dataview.chart.labelkey') }}</label>
                <multiselect v-model="chart.series[seriesIndex].labelKey" class="custom-dropdown-options enable-option-icon"
                  :max-height="enablePositionFixed? 300 : 150"
                  :options="fields.map(f => { return typeof f === 'string' ? f : `${f.agFunction.toUpperCase()}(${f.field})`})"
                  :placeholder="''"
                  :searchable="false" 
                  :allow-empty="false"
                  :showLabels="false"
                  @input="changeType(seriesIndex)">
                  <template slot="option" slot-scope="props">
                    <font-awesome-icon class="selected-option-icon" v-if="chart.series[seriesIndex].labelKey == props.option" :icon="['far', 'check']" />
                    <span class="option__title">{{ props.option }}</span>
                  </template>
                </multiselect>
              </div>

              <div class="mt-2">
                <label for="ykeys">{{ $t('dataview.chart.anglekey') }}</label>
                <button class="btn-action mr-3" @click="addAngleKey(seriesIndex)"><font-awesome-icon :icon="['far', 'plus']"/></button>
                
                <BadgeGroup v-model="chart.series[seriesIndex].angleKeys" class="d-inline-block w-100 mb-3">
                  <template v-slot:default="{ item, index }">
                    <Badge @badgeRemove="removeAngleKey(seriesIndex)" @badgeClick="editAngleName(seriesIndex)"
                      :text="valueText(item)" 
                      variant="white" 
                      :pillable="item !== null && !!item.pillable" :key="index" />
                    </template>
                </BadgeGroup>
              </div>
            </template>
            
            <div class="mt-2" v-if="chart.series[seriesIndex].type !== null && !isPie(seriesIndex) && !isBar(seriesIndex)">
              <label class="mr-2" for="xkey">{{ $t('dataview.chart.xkey') }}</label>
              <button class="btn-action mr-3" @click="addXName(seriesIndex)"><font-awesome-icon :icon="['far', 'plus']"/></button>
            
              <BadgeGroup v-model="chart.series[seriesIndex].xKey" class="d-inline-block w-100 mb-3">
                <template v-slot:default="{ item, index }">
                  <Badge @badgeRemove="removeXKey(seriesIndex, index)" @badgeClick="editXName(seriesIndex, index)"
                    :text="valueText(item)" 
                    variant="white" 
                    :pillable="item !== null && !!item.pillable" :key="index" />
                  </template>
              </BadgeGroup>
            </div>
                  
            <b-form-group :label="$t('dataview.field.format_horizontal')" label-for="format" v-if="chart.series[seriesIndex].type !== null && !isPie(seriesIndex) && !isBar(seriesIndex) && !isHistogram(seriesIndex)">
              <b-input-group>
                <b-form-input id="format" type="text"
                  data-vv-as="Name"
                  data-vv-name="chart.format"
                  data-vv-delay="500"
                  v-model="chart.format" trim >
                </b-form-input>
              </b-input-group>
            </b-form-group>
              
            <div class="mt-2" v-if="chart.series[seriesIndex].type !== null && !isPie(seriesIndex) && chart.series[seriesIndex].type !== 'histogram'">
              <label for="ykeys">{{ yKeysTitle(seriesIndex) }}</label>
              <button class="btn-action mr-3" @click="addYKey(seriesIndex)"><font-awesome-icon :icon="['far', 'plus']"/></button>

              <b-form-group class="float-right" v-if="chart.series[seriesIndex].type === 'bar' || chart.series[seriesIndex].type === 'column' || chart.series[seriesIndex].type === 'area'">
                <b-form-radio class="d-inline-block mr-4" v-model="chart.series[seriesIndex].grouped" name="grouped" :value="false">{{ $t('dataview.chart.stacked') }}</b-form-radio>
                <b-form-radio class="d-inline-block mr-4" v-model="chart.series[seriesIndex].grouped" name="grouped" :value="true">{{ $t('dataview.chart.grouped') }}</b-form-radio>
              </b-form-group>
              
              <BadgeGroup v-model="chart.series[seriesIndex].yKeys" class="d-inline-block w-100 mb-3" @change="yChange(seriesIndex, ...arguments)">
                <template v-slot:default="{ item, index }">
                  <Badge @badgeRemove="removeYKey(seriesIndex, index)" @badgeClick="editYName(seriesIndex, index)"
                    :text="valueText(item)" 
                    variant="white" 
                    :pillable="item !== null && !!item.pillable" :key="index" />
                  </template>
              </BadgeGroup>
            </div>
            
            <div v-if="chart.series[seriesIndex].type === 'bubble'"  class="size-axis-container">
              <label class="mr-2" for="xkey">{{ $t('dataview.chart.sizekey') }}</label>
              <button class="btn-action mr-3" @click="editSizeName(seriesIndex)"><font-awesome-icon :icon="['far', 'plus']"/></button>
              
              <BadgeGroup v-model="chart.series[seriesIndex].sizeKey" class="d-inline-block w-100 mb-3">
                <template v-slot:default="{ item, index }">
                  <Badge @badgeRemove="removeSizeKey(seriesIndex, index)" @badgeClick="editSizeName(seriesIndex)"
                    :text="valueText(item)" 
                    variant="white" 
                    :pillable="item !== null && !!item.pillable" :key="index" />
                  </template>
              </BadgeGroup>
            </div>                 
           
            <!-- For bar graphs the x value is on the vertical axis -->
            <div class="mt-2" v-if="chart.series[seriesIndex].type !== null && isBar(seriesIndex)">
              <label class="mr-2" for="xkey">{{ $t('dataview.chart.ykeys') }}</label>
              <button class="btn-action mr-3" @click="addXName(seriesIndex)"><font-awesome-icon :icon="['far', 'plus']"/></button>
            
              <BadgeGroup v-model="chart.series[seriesIndex].xKey" class="d-inline-block w-100 mb-3">
                <template v-slot:default="{ item, index }">
                  <Badge @badgeRemove="removeXKey(seriesIndex, index)" @badgeClick="editXName(seriesIndex, index)"
                    :text="valueText(item)" 
                    variant="white" 
                    :pillable="item !== null && !!item.pillable" :key="index" />
                  </template>
              </BadgeGroup>
            </div>
            
            <b-form-group :label="$t('dataview.field.format_vertical')" label-for="format" v-if="chart.series[seriesIndex].type !== null && isBar(seriesIndex)">
              <b-input-group>
                <b-form-input id="format" type="text"
                  data-vv-as="Name"
                  data-vv-name="chart.format"
                  data-vv-delay="500"
                  v-model="chart.format" trim >
                </b-form-input>
              </b-input-group>
            </b-form-group>
              
          </div>
        </div>
        
        <label class="text-n-line mt-4" :class="axesExpanded ? null : 'collapsed'"
          :aria-expanded="axesExpanded ? 'true' : 'false'"
          aria-controls="collapse-4"
          @click="axesExpanded = !axesExpanded"
          v-if="showAxesSettings && chart.series.length > 0 && !isPie(0)">
          <span>{{ $t('dataview.chart.axes') }}
            <font-awesome-icon :icon="['far', 'chevron-up']" class="collapsable-icon" v-if="axesExpanded"/>
            <font-awesome-icon :icon="['far', 'chevron-down']" class="collapsable-icon" v-else />
          </span>
          
        </label>
        
        <b-collapse id="collapse-4" v-model="axesExpanded" class="mt-2">
          <div class="mt-1">
            <label for="addseries">{{ $t('dataview.chart.addaxes') }}</label>
            <button name="addaxes" class="btn-action" @click="addAxes"><font-awesome-icon :icon="['far', 'plus']"/></button>
          </div>
          <div class="chart-axes" v-if="chart.series.length > 0 && !isPie(0)">
            <div v-bind:key="axesIndex" class="axes-container" v-for="(key, axesIndex) in chart.axes">
              <button class="series-remove-btn" @click="removeAxes(axesIndex)"><font-awesome-icon :icon="['far', 'trash-can']"/></button>
              <label class="align-bottom mr-2" for="axestype">{{ $t('dataview.chart.type') }}</label>
              <multiselect v-model="chart.axes[axesIndex].type" class="custom-dropdown-options enable-option-icon"
                :max-height="enablePositionFixed? 300 : 150"
                :options="axesTypes"
                :placeholder="''"
                :searchable="false" 
                :allow-empty="false"
                :showLabels="false">
                <template slot="option" slot-scope="props">
                  <font-awesome-icon class="selected-option-icon" v-if="chart.axes[axesIndex].type == props.option" :icon="['far', 'check']" />
                  <span class="option__title">{{ props.option }}</span>
                </template>
              </multiselect>
              <label class="align-bottom ml-4 mr-2" for="axesposition">{{ $t('dataview.chart.position') }}</label>
              <multiselect v-model="chart.axes[axesIndex].position" class="custom-dropdown-options enable-option-icon"
                :max-height="enablePositionFixed? 300 : 150"
                :options="axesPositions"
                :placeholder="''"
                :searchable="false" 
                :allow-empty="false"
                :showLabels="false">
                <template slot="option" slot-scope="props">
                  <font-awesome-icon class="selected-option-icon" v-if="chart.axes[axesIndex].position == props.option" :icon="['far', 'check']" />
                  <span class="option__title">{{ props.option }}</span>
                </template>
              </multiselect>
              <label class="align-bottom ml-4 mr-2" for="labelrotation">{{ $t('dataview.chart.labelrotation') }}</label>
              <b-form-input class="w-auto d-inline-block" type="number" v-model="chart.axes[axesIndex].label.rotation" ></b-form-input>
              <label class="align-bottom mr-2" for="axestype">{{ $t('dataview.chart.axestitle') }}</label>  
              <div class="name-edit">
                <div class="d-inline-block"
                  @click.stop="editAxesTitle(axesIndex)">
                  <div
                    class="name-label d-inline-block">
                    {{ chart.axes[axesIndex].title.text !== null && chart.axes[axesIndex].title.text !== '' ? chart.axes[axesIndex].title.text : $t('dataview.placeholder.name') }}
                  </div>
                  <div class="d-inline-block ml-2 mr-2">
                    <font-awesome-icon class="edit-icon-color" :icon="['far', 'pen-to-square']"/>
                  </div>
                </div>
              </div>
              
              <div>
                <label class="mr-1">{{ $t(`dataview.chart.key`) }}</label>
                <button class="btn-action" @click="addAxesKey(axesIndex)"><font-awesome-icon :icon="['far', 'plus']"/></button>
                <BadgeGroup v-model="chart.axes[axesIndex].keys" class="d-inline-block w-100 mb-3">
                  <template v-slot:default="{ item, index }">
                    <Badge @badgeRemove="removeAxesKey(axesIndex, index)" @badgeClick="editAxesKey(axesIndex, index)"
                      :text="valueText(item)" 
                      variant="white" 
                      :pillable="item !== null && !!item.pillable" :key="index" />
                    </template>
                </BadgeGroup>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
                     
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template>
          <b-button v-if="canEdit('DATAVIEW')" size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        
      </template>
    </b-modal>
    
    <b-modal :title="$t('dataview.confirmation.pick_colour')"
        v-model="promptColour"
        size="sm"
        :ok-title="$t('button.confirm')"
        @ok="colourPickerOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <chrome-picker class="colour-picker" v-model="colour" />
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <b-modal :title="$t('dataview.confirmation.edit_field')"
        v-model="xNameEditShow"
        size="md"
        no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
        @ok="xNameEditOk"
        >
      <label class="d-block mr-1">{{ $t(`dataview.chart.field_name`) }}</label>
      <multiselect v-model="xKeyEditVal" class="custom-dropdown-options enable-option-icon"
        :max-height="enablePositionFixed? 300 : 150"
        :options="xFields()"
        :placeholder="''"
        :searchable="false" 
        :allow-empty="false"
        :showLabels="false"
        @input="changeXKey">
        <template slot="option" slot-scope="props">
          <font-awesome-icon class="selected-option-icon" v-if="xKeyEditVal == props.option" :icon="['far', 'check']" />
          <span class="option__title">{{ props.option }}</span>
        </template>
      </multiselect>
      <b-form-group class="mt-2" :label="$t('dataview.chart.display_name')" label-for="xname">
        <b-input-group>
          <b-form-input type="text"
            v-model="xNameEditVal" >
          </b-form-input>
        </b-input-group>
      </b-form-group>  
                  
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template>
          <b-button :disabled="xKeyEditVal === null" size="sm" variant="success" @click="xNameEditOk">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        
      </template> 
    </b-modal>
    
    <b-modal :title="$t('dataview.confirmation.edit_field')"
        v-model="sizeNameEditShow"
        size="md"
        no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
        @ok="sizeNameEditOk"
        >
        
      <b-alert variant="danger" dismissible :show="showSizeError" @dismissed="dismissSizeAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertSizeMsg }}
      </b-alert>
      
      <label class="d-block mr-1">{{ $t(`dataview.chart.field_name`) }}</label>
      <multiselect v-model="sizeKeyEditVal" class="custom-dropdown-options enable-option-icon"
        :max-height="enablePositionFixed? 300 : 150"
        :options="numberFields"
        :placeholder="''"
        :searchable="false" 
        :allow-empty="false"
        :showLabels="false"
        @input="changeSizeKey">
        <template slot="option" slot-scope="props">
          <font-awesome-icon class="selected-option-icon" v-if="sizeKeyEditVal == props.option" :icon="['far', 'check']" />
          <span class="option__title">{{ props.option }}</span>
        </template>
      </multiselect>
      <b-form-group class="mt-2" :label="$t('dataview.chart.display_name')" label-for="sizename">
        <b-input-group>
          <b-form-input type="text"
            v-model="sizeNameEditVal" >
          </b-form-input>
        </b-input-group>
      </b-form-group>   
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template>
          <b-button :disabled="sizeKeyEditVal === null" size="sm" variant="success" @click="sizeNameEditOk">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        
      </template>
    </b-modal>
    
    <b-modal :title="$t('dataview.confirmation.edit_field')"
        v-model="yNameEditShow"
        size="md"
        no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
        @ok="yNameEditOk"
        >
      <label class="d-block mr-1">{{ $t(`dataview.chart.field_name`) }}</label>
      <multiselect v-model="yKeyEditVal" class="custom-dropdown-options enable-option-icon"
        :max-height="enablePositionFixed? 300 : 150"
        :options="numberFields"
        :placeholder="''"
        :searchable="false" 
        :allow-empty="false"
        :showLabels="false"
        @input="changeYKey">
        <template slot="option" slot-scope="props">
          <font-awesome-icon class="selected-option-icon" v-if="yKeyEditVal == props.option" :icon="['far', 'check']" />
          <span class="option__title">{{ props.option }}</span>
        </template>
      </multiselect>
      <b-form-group class="mt-2" :label="$t('dataview.chart.display_name')" label-for="yname">
        <b-input-group>
          <b-form-input type="text"
            v-model="yNameEditVal" >
          </b-form-input>
        </b-input-group>
      </b-form-group>
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template>
          <b-button :disabled="yKeyEditVal === null" size="sm" variant="success" @click="yNameEditOk">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        
      </template>
    </b-modal>
    
    <b-modal :title="$t('dataview.confirmation.edit_field')"
        v-model="axesKeyEditShow"
        size="md"
        no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
        @ok="axesKeyEditOk"
        >
      <label class="mr-1">{{ $t(`dataview.chart.field_name`) }}</label>
      <multiselect v-model="axesKeyEditVal" class="custom-dropdown-options enable-option-icon"
        :max-height="enablePositionFixed? 300 : 150"
        :options="fields"
        :placeholder="''"
        :searchable="false" 
        :allow-empty="false"
        :showLabels="false"
        @input="changeYKey">
        <template slot="option" slot-scope="props">
          <font-awesome-icon class="selected-option-icon" v-if="axesKeyEditVal == props.option" :icon="['far', 'check']" />
          <span class="option__title">{{ props.option }}</span>
        </template>
      </multiselect>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <b-modal :title="$t('dataview.confirmation.edit_name')"
        v-model="axesTitleEditShow"
        size="sm"
        no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
        @ok="axesTitleEditOk"
        >
        
      <b-form-group :label="$t('dataview.chart.axestitle')" label-for="axestitle">
        <b-input-group>
          <b-form-input type="text"
            v-model="axesTitleEditVal" >
          </b-form-input>
        </b-input-group>
      </b-form-group>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('dataview.confirmation.edit_field')"
        v-model="angleNameEditShow"
        size="md"
        no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
        @ok="angleNameEditOk"
        >
      <label class="d-block mr-1">{{ $t(`dataview.chart.field_name`) }}</label>
      <multiselect v-model="angleKeyEditVal" class="custom-dropdown-options enable-option-icon"
        :max-height="enablePositionFixed? 300 : 150"
        :options="numberFields"
        :placeholder="''"
        :searchable="false" 
        :allow-empty="false"
        :showLabels="false"
        @input="changeAngleKey">
        <template slot="option" slot-scope="props">
          <font-awesome-icon class="selected-option-icon" v-if="angleKeyEditVal == props.option" :icon="['far', 'check']" />
          <span class="option__title">{{ props.option }}</span>
        </template>
      </multiselect>
      <b-form-group class="mt-2" :label="$t('dataview.chart.display_name')" label-for="yname">
        <b-input-group>
          <b-form-input type="text"
            v-model="angleNameEditVal" >
          </b-form-input>
        </b-input-group>
      </b-form-group>
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template>
          <b-button :disabled="angleKeyEditVal === null" size="sm" variant="success" @click="angleNameEditOk">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        
      </template>
    </b-modal>
    
  </div>
</template>

<script>
import { Chrome } from 'vue-color'
import { cloneDeep } from 'lodash';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { getFieldType, getMacroType } from '@/helpers';
import Multiselect from 'vue-multiselect';

export default {
  name: 'ChartModal',
  components: {
    'chrome-picker': Chrome,
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    Multiselect
  },
  props: {
    show: { type: Boolean, required: true },
    data: { type: Object, default: null },
    fields: { type: Array, default: null },
    title: { type: String, default: null },
    schema: { type: Object, default: null },
    macros: { type: Object, default: null },
    agFuncs: { type: Object, default: null }
  },
  data() {
    return {
      alertError: false,
      alertMsg: null,
      alertSizeMsg: null,
      modalShow: false,
      axesTypes: [
        'category',
        'number',
        'time'
      ],
      axesPositions: [
        'bottom',
        'left',
        'right',
        'top'
      ],
      showAxesSettings: false, // hiding axes settings from user
      axesExpanded: false,
      chart: { 
        id: null,
        name: '', 
        description: '',
        series: [],
        format: '',
        axes: [],
        legend: { 
          enabled: true,
          position: 'right'
        },
        display_title: true
      },
      
      promptColour: false,
      colour: '#194d33',
      editSeriesIndex: -1,
      editFillIndex: -1,
      editStrokeIndex: -1,
      
      xNameEditIndex: -1,
      xNameEditXIndex: -1,
      xNameEditShow: false,
      xNameEditVal: null,
      xKeyEditVal: null,
      
      yNameEditIndex: -1,
      yNameEditYIndex: -1,
      yNameEditShow: false,
      yNameEditVal: null,
      yKeyEditVal: null,
      
      sizeNameEditIndex: -1,
      sizeNameEditShow: false,
      sizeNameEditVal: null,
      sizeKeyEditVal: null,
      
      axesKeyEditIndex: -1,
      axesKeyEditKeyIndex: -1,
      axesKeyEditShow: false,
      axesKeyEditVal: null,
      
      axesTitleEditIndex: -1,
      axesTitleEditShow: false,
      axesTitleEditVal: null,

      angleNameEditIndex: -1,
      angleNameEditShow: false,
      angleKeyEditVal: null,
      angleNameEditVal: null,
      
      fills: ['#F57A20',
              '#283EA7',
              '#781CA3',
              '#EBF320',
              '#A04400',
              '#6AD81C',
              '#F32025',
              '#139393'],
      strokes: ['#6D350D',
                '#121D52',
                '#340A48',
                '#6B6F0B',
                '#A04400',
                '#408211',
                '#7D1215',
                '#006060'],
                
      enablePositionFixed: false,

      chartLegendPositionStr: 'right' //a workaround for multiselect compeonent which can't accept null value.
    }
  },
  created() {
    this.fieldValidateUtil = fieldValidateUtil;
    // Create ResizeObserver
    this.observer = new ResizeObserver(entries => {
      if (entries.length > 0) {
        this.enablePositionFixed = !this.hasScrollbar();
      }
    });
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    if (this.observer != null) {
      this.observer.disconnect();
      this.observer = null;
    }
  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        this.alertMsg = null;
        this.modalShow = newValue;
        this.axesExpanded = false;
        if (this.data !== null) {
          if (typeof this.data.legend === 'undefined') {
            this.data.legend = { enabled: true, position: 'right'};
          }
          this.chart = cloneDeep(this.data);
          if (typeof this.chart.display_title === 'undefined') {
            this.chart.display_title = true;
          }
          this.chartLegendPositionStr = this.chart.legend.position == null? 'null' : this.chart.legend.position;

          //Set up angkeys property based on angleKey.
          if (this.chart != null && this.chart.series != null && Array.isArray(this.chart.series)) {
            const series = this.chart.series;
            for(let i = 0, len = series.length; i < len; i++) {
              this.$set(series[i], 'angleKeys', []);
              if (series[i].angleKey != null) {
                series[i].angleKeys.push(series[i].angleKey);
              }
            }
          }

        }
        else {
          this.chart.id = null;
          this.chart.name = '';
          this.chart.description = '';
          this.chart.series = [];
          this.chart.axes = [];
          this.chart.format = '';
          this.chart.legend = { enabled: true, position: 'right'};
          this.chartLegendPositionStr = 'right';
          this.addSeries();
        }
      }
    },
    modalShow(newValue) {
      if (newValue == true) {
        this.setupModalBodyHeightChangeObserver();
      } else {
        this.cleanupModalBodyHeightChangeObserver();
      }
    }
  },
  computed: {
    chartTypes() {
      return [
          { value: 'area', text: this.$t('dataview.chart_type.area') },
          { value: 'bar', text: this.$t('dataview.chart_type.bar') },
          { value: 'bubble', text: this.$t('dataview.chart_type.bubble') },
          { value: 'column', text: this.$t('dataview.chart_type.column') },
          { value: 'doughnut', text: this.$t('dataview.chart_type.doughnut') },
          { value: 'histogram', text: this.$t('dataview.chart_type.histogram') },
          { value: 'line', text: this.$t('dataview.chart_type.line') },
          { value: 'pie', text: this.$t('dataview.chart_type.pie') },
          { value: 'scatter', text: this.$t('dataview.chart_type.scatter') }
      ]
    },
    legendPositions() {
      return [
        { value: 'null', text: this.$t('dataview.legend.none') },
        { value: 'bottom', text: this.$t('dataview.legend.bottom') },
        { value: 'left', text: this.$t('dataview.legend.left') },
        { value: 'right', text: this.$t('dataview.legend.right') },
        { value: 'top', text: this.$t('dataview.legend.top') }
      ]
    },
    showError() {
      return this.alertMsg != null;
    },
    showSizeError() {
      return this.alertSizeMsg != null;
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'chart.name');
    },
    supportsMultipleSeries() {
      return false; //this.chart.series.length === 0 || (this.chart.series[0].type !== 'pie' && this.chart.series[0].type !== 'doughnut')
    },
    numberFields() {
      var result = [];
      if (this.fields) {
        for (var field of this.fields) {
          var type = getFieldType(field, this.schema);
          if (type === 'Macro') {
            type = getMacroType(field, this.macros);
          }
          
          if (type === 'Cost' ||
              type === 'Duration' ||
              type === 'MinuteDuration' ||
              type === 'Progress' ||
              type === 'Integer' ||
              type === 'Long'
              || type === 'Double' ||
              type === 'Float') {
            result.push(field);
          }
          else if (typeof field === 'object' && field.agFunction !== null) {
            result.push(`${field.agFunction.toUpperCase()}(${field.field})`);
          }
        }
      }
      return result;
    }
  },
  methods: {
    valueText(item) {
      if (typeof item === 'object') {
        return `${item.agFunction.toUpperCase()}(${item.field})`;
      }
      return item.replace(/\(A\)|\(A,B\)|\(A,B,C\)/, "()");
    },
    xFields() {
      if (this.xNameEditIndex !== -1 && this.chart.series[this.xNameEditIndex].type === 'histogram') {
        return this.numberFields;
      }
      return this.fields ? this.fields.map(f => { return typeof f === 'string' ? f : `${f.agFunction.toUpperCase()}(${f.field})`}) : [];
    },
    canAddYKey(index) {
      return (this.chart.series[index].yKeys.length === 0) ||
        (this.chart.series[index].type !== 'line' &&
          this.chart.series[index].type !== 'bubble' &&
          this.chart.series[index].type !== 'scatter' &&
          this.chart.series[index].type !== 'histogram')
    },
    isPie(index) {
      return this.chart.series[index].type === 'pie' || this.chart.series[index].type === 'doughnut';
    },
    isBar(index) {
      return this.chart.series[index].type === 'bar';
    },
    isHistogram(index) {
      return this.chart.series[index].type === 'histogram';
    },
    yKeysTitle(index) {
      if (!this.isBar(index)) {
        return this.$t('dataview.chart.ykeys');
      }
      else {
        return this.$t('dataview.chart.xkey');
      }
    },
    editAxesTitle(index) {
      this.axesTitleEditIndex = index;
      this.axesTitleEditVal = this.chart.axes[this.axesTitleEditIndex].title.text;
      this.axesTitleEditShow = true;
    },
    axesTitleEditOk() {
      this.chart.axes[this.axesTitleEditIndex].title.text = this.axesTitleEditVal;
    },
    addXName(seriesIndex) {
      this.xNameEditIndex = seriesIndex;
      if (this.chart.series[seriesIndex].type !== 'histogram') {
        this.xNameEditXIndex = this.chart.series[seriesIndex].xKey.length;
        this.xNameEditVal = null;
        this.xKeyEditVal = null;
      }
      else {
        this.xNameEditXIndex = 0;
        this.xNameEditVal = this.chart.series[this.xNameEditIndex].xName;
        this.xKeyEditVal = this.chart.series[this.xNameEditIndex].xKey[0];
      }
      
      this.xNameEditShow = true;
    },
    editXName(seriesIndex, nameIndex) {      
      this.xNameEditIndex = seriesIndex;
      this.xNameEditXIndex = nameIndex;
      this.xNameEditVal = this.chart.series[this.xNameEditIndex].xName;
      this.xKeyEditVal = this.chart.series[this.xNameEditIndex].xKey[nameIndex];
      this.xNameEditShow = true;
    },
    removeXKey(seriesIndex, index) {
      const val = this.chart.series[seriesIndex].xKey.splice(index, 1);
      this.chart.format = this.chart.format.replace(val[0], '');
    },
    removeSizeKey(index) {
      this.chart.series[index].sizeKey = [];
    },
    xNameEditOk() {
      this.chart.series[this.xNameEditIndex].xName = this.xNameEditVal;
      this.chart.series[this.xNameEditIndex].xKey[this.xNameEditXIndex] = this.xKeyEditVal;
      
      // change axes
      this.chart.axes[0].keys = this.chart.series[this.xNameEditIndex].xKey;
      this.chart.axes[0].title.text = this.chart.series[this.xNameEditIndex].xName;
      
      // change format
      this.chart.format = this.valueText(this.chart.series[this.xNameEditIndex].xKey.join(' '));
      
      var type = getFieldType(this.xKeyEditVal, this.schema);
      if (type === 'Macro') {
        type = getMacroType(this.xKeyEditVal, this.macros);
      }
      
      if (type === 'Date') {
        this.chart.axes[0].type = 'time';
      }
      else if (this.chart.series[this.xNameEditIndex].type === 'histogram' || type === 'Duration' || type === 'Cost' || type === 'Progress' || type === 'Integer' || type === 'Long' || type === 'Double' || type === 'Float') {
        this.chart.axes[0].type = 'number';
      }
      else {
        this.chart.axes[0].type = 'category';
      }
      this.xNameEditShow = false;
    },
    editSizeName(index) {
      this.sizeNameEditIndex = index;
      this.sizeNameEditVal = this.chart.series[this.sizeNameEditIndex].sizeName;
      this.sizeKeyEditVal = this.chart.series[this.sizeNameEditIndex].sizeKey[0];
      this.sizeNameEditShow = true;
    },
    sizeNameEditOk() {
      this.chart.series[this.sizeNameEditIndex].sizeName = this.sizeNameEditVal;
      this.chart.series[this.sizeNameEditIndex].sizeKey = [this.sizeKeyEditVal];
      this.sizeNameEditShow = false;
    },
    editYName(index, yindex) {
      this.yNameEditIndex = index;
      this.yNameEditYIndex = yindex;
      this.yNameEditVal = this.chart.series[this.yNameEditIndex].yNames[this.yNameEditYIndex];
      this.yKeyEditVal = this.chart.series[this.yNameEditIndex].yKeys[this.yNameEditYIndex];
      this.yNameEditShow = true;
    },
    yNameEditOk() {
      this.chart.series[this.yNameEditIndex].yNames.splice(this.yNameEditYIndex, 1, this.yNameEditVal);
      this.chart.series[this.yNameEditIndex].yKeys.splice(this.yNameEditYIndex, 1, this.yKeyEditVal);
      
      if (this.chart.series[this.yNameEditIndex].type === 'line' ||
          this.chart.series[this.yNameEditIndex].type === 'bubble' ||
          this.chart.series[this.yNameEditIndex].type === 'scatter' ||
          this.chart.series[this.yNameEditIndex].type === 'histogram') {
        this.chart.series[this.yNameEditIndex].yKey = this.chart.series[this.yNameEditIndex].yKeys[this.yNameEditYIndex];
        this.chart.series[this.yNameEditIndex].yName = this.chart.series[this.yNameEditIndex].yNames[this.yNameEditYIndex];
      }
      
      // change axes
      if (this.yNameEditIndex === 0 && this.yNameEditYIndex === 0) { // first series
        this.chart.axes[1].keys = this.chart.series[this.yNameEditIndex].yKeys;
        this.chart.axes[1].title.text = this.chart.series[this.yNameEditIndex].yNames[0];
      }
      else if (this.yNameEditIndex === 1 && this.yNameEditYIndex === 0) {
        this.chart.axes[2].keys = this.chart.series[this.yNameEditIndex].yKeys;
        this.chart.axes[2].title.text = this.chart.series[this.yNameEditIndex].yNames[0];
      }
      this.yNameEditShow = false;
    },
    yChange(index, args) {
      if (args.moved) {
        const newIndex = args.moved.newIndex;
        const oldIndex = args.moved.oldIndex;
        const fromVal = this.chart.series[index].yNames[oldIndex];
        this.chart.series[index].yNames.splice(oldIndex, 1);
        this.chart.series[index].yNames.splice(newIndex, 0, fromVal);
      }
    },
    editAxesKey(index, keyindex) {
      this.axesKeyEditIndex = index;
      this.axesKeyEditKeyIndex = keyindex;
      this.axesKeyEditVal = this.chart.axes[index].keys[keyindex];
      this.axesKeyEditShow = true;
    },
    axesKeyEditOk() {
      this.chart.axes[this.axesKeyEditIndex].keys.splice(this.axesKeyEditKeyIndex, 1, this.axesKeyEditVal);
      if (this.chart.axes[this.axesKeyEditIndex].title === null) {
        this.$set(this.chart.axes[this.axesKeyEditIndex].title, 'text', this.updateName(this.valueText(this.chart.axes[this.axesKeyEditIndex].keys[0])));
      }
    },
    getChartTypes(index) {
      if (index === 0) {
        return this.chartTypes;
      }

      // pie not supported in multiple series
      return this.chartTypes.filter(c => c !== 'pie' && c !== 'doughnut');
    },
    editFillColour(index, yindex) {
      this.editSeriesIndex = index;
      this.editFillIndex = yindex;
      this.colour = this.chart.series[index].fills[yindex];
      this.promptColour = true;
    },
    editStrokeColour(index, yindex) {
      this.editSeriesIndex = index;
      this.editStrokeIndex = yindex;
      this.colour = this.chart.series[index].strokes[yindex];
      this.promptColour = true;
    },
    getFillStyle(index, yindex) {
      return `background-color: ${this.chart.series[index].fills[yindex]}`;
    },
    getStrokeStyle(index, yindex) {
      return `background-color: ${this.chart.series[index].strokes[yindex]}`;
    },
    colourPickerOk() {
      if (this.editFillIndex !== -1) {
        this.chart.series[this.editSeriesIndex].fills[this.editFillIndex] = this.colour.hex8;
        this.editFillIndex = -1;
      }
      
      if (this.editStrokeIndex !== -1) {
        this.chart.series[this.editSeriesIndex].strokes[this.editStrokeIndex] = this.colour.hex8;
        this.editStrokeIndex = -1;
      }
    },
    dismissAlert() {
      this.alertMsg = null;
      this.alertError = false;
    },
    dismissSizeAlert() {
      this.alertSizeMsg = null;
    },
    ok() {
      if (this.chart.name === null ||
          this.chart.name === "") {
        this.alertMsg = this.$t('dataview.chart.error_name');
        this.alertError = true;
        return;    
      }
      
      for (var series of this.chart.series) {
        if (series.xKey.length === 0 &&
                series.type !== 'pie' &&
                series.type !== 'doughnut') {
          this.alertMsg = this.$t('dataview.chart.error_xkey');
          this.alertError = true;
          return;    
        }
        else if (series.yKeys.length == 0 &&
                series.type !== 'pie' &&
                series.type !== 'doughnut' &&
                series.type !== 'histogram') {
          this.alertMsg = this.$t('dataview.chart.error_ykeys');
          this.alertError = true;
          return;    
        }
        else if (series.labelKey === null &&
                (series.type === 'pie' ||
                 series.type === 'doughnut')) {
          this.alertMsg = this.$t('dataview.chart.error_labelkey');
          this.alertError = true;
          return;    
        }
        else if (series.angleKeys.length === 0 &&
                (series.type === 'pie' ||
                 series.type === 'doughnut')) {
          this.alertMsg = this.$t('dataview.chart.error_anglekey');
          this.alertError = true;
          return;    
        }

        //Transfer angleKeys value to angleKey, and remove the angleKeys property.
        if (series.angleKeys != null && series.angleKeys.length > 0) {
          series.angleKey = series.angleKeys[0];
        }
        delete series.angleKeys;

        //Remove title property. The existence of title property will cause trouble in scatter line and bubble's legend if the title is an object.
        //Title in scatter/bubble and line refers to yName or yKey when it exists. Please checkout out the official documentation of ag chart for more details. 
        if (series.type !== 'pie' && series.type !== 'doughnut' && series.title != null && typeof series.title === 'object') {
          delete series.title;
        }
        
        //Set empty string to text when title or title.text is null or undefined
        if ((series.type === 'pie' || series.type === 'doughnut') && (series.title == null || series.title.text == null)) {
          if (series.title == null) {
            series.title = {};
          }
          if (series.title.text == null) {
            series.title.text = '';
          }
        }

        if (series.title != null && (series.title.enabled === undefined || series.title.enabled === null)) {
          this.$set(series.title, 'enabled', false);
        }
        
        if (series.type === 'histogram') {
          this.chart.axes[1].title.text = 'Frequency';
          this.chart.axes[1].keys = [];
        }
      }
      
      if ((series.type === 'pie' || series.type === 'doughnut' || series.type === 'histogram') &&
          this.chart.datesMode === 'timeseries') {
        this.chart.datesMode = 'current';
      }

      this.$emit('update:show', false);
      this.$emit('success', this.chart);
    },
    addYKey(index) {
      this.yNameEditIndex = index;
      this.yNameEditYIndex = this.canAddYKey(index) ? this.chart.series[index].yKeys.length : 0;
      this.yNameEditVal = null;
      this.yKeyEditVal = null;
      this.yNameEditShow = true;
      this.chart.series[index].fills.splice(this.yNameEditYIndex, 1, this.fills[this.yNameEditYIndex]);
      this.chart.series[index].strokes.splice(this.yNameEditYIndex, 1, this.strokes[this.yNameEditYIndex]);
    },
    removeYKey(index, yindex) {
      this.chart.series[index].yKeys.splice(yindex, 1);
      this.chart.series[index].yNames.splice(yindex, 1);
      this.chart.series[index].fills.splice(yindex, 1);
      this.chart.series[index].strokes.splice(yindex, 1);
    },
    addSeries() {
      var xKey = [];
      var xName = null;
      if (this.chart.series.length !== 0) {
        xKey = [this.chart.series[0].xKey[0]];
        xName = this.chart.series[0].xName;
      }
      
      this.chart.series.push({
        type: null,
        labelKey: null,
        angleKey: null,
        xKey: xKey,
        yKeys: [],
        fills: [this.fills[this.chart.series.length]],
        strokes: [this.strokes[this.chart.series.length]],
        xName: xName,
        yNames: [null],
        sizeKey: [],
        sizeName: null,
        grouped: false,
        title: {
          text: null
        },
        angleKeys: [] //This property is used as a temporary placeholder and will be removed before data is emitted to parent component.
      });
      
      if (this.chart.axes.length === 0) {
        // x-axis
        this.chart.axes.push({ type: 'category', position: 'bottom', title: { text: null, fontFamily: 'Roboto' }, label: { rotation: 60, fontFamily: 'Roboto' }, keys: [] });
        
        // y-axis
        this.chart.axes.push({ type: 'number', position: 'left', title: { text: null, fontFamily: 'Roboto' }, label: { rotation: 30, fontFamily: 'Roboto' }, keys: [] });
      }
      
      if (this.chart.series.length > 1) {
        // add another axes
        // y-axis
        this.chart.axes.push({ type: 'number', position: 'right', title: { text: null }, label: { rotation: 60 }, keys: [] });
      }
    },
    removeSeries(index) {
      this.chart.series.splice(index, 1);
      
      if (this.chart.series.length === 0) {
        this.chart.axes.splice(0, this.chart.axes.length);
      }
      else if (this.chart.series.length === 1) {
        this.chart.axes.splice(2, 1);
      }
    },
    getRandomColour() {
      return "#" + Math.floor(Math.random()*16777215).toString(16);
    },
    addAxes() {
      this.chart.axes.push({ type: null, position: null, title: { text: null }, label: { rotation: 60 }, keys: [] });
    },
    removeAxes(index) {
      this.chart.axes.splice(index, 1);
    },
    addAxesKey(index) {
      this.axesKeyEditIndex = index;
      this.axesKeyEditKeyIndex = this.chart.axes[index].keys.length;
      this.axesKeyEditVal = null;
      this.axesKeyEditShow = true;
    },
    removeAxesKey(index, axesindex) {
      this.chart.axes[index].keys.splice(axesindex, 1);
    },
    changeXKey() {
      this.xNameEditVal = this.updateName(this.valueText(this.xKeyEditVal));
    },
    changeYKey(index, yindex) {
      this.yNameEditVal = this.updateName(this.valueText(this.yKeyEditVal));
    },
    changeType(index) {
      if (this.chart.series[index].type === 'line' ||
          this.chart.series[index].type === 'bubble' ||
          this.chart.series[index].type === 'scatter') {
        this.chart.series[index].yKey = this.chart.series[index].yKeys[0];
        this.chart.series[index].yName = this.chart.series[index].yNames[0];
        if (this.chart.series[index].yKeys.length > 1) {
          this.chart.series[index].yKeys.splice(1);
        }
      }
      else if (this.chart.series[index].type === 'pie' ||
                 this.chart.series[index].type === 'doughnut') {
        this.chart.series[index].fills = this.fills;
        this.chart.series[index].strokes = this.strokes;
        if (this.chart.series.length > 1) {
          this.chart.series.splice(1);
        }
      }
      
      if (this.chart.series[index].type === 'histogram') {
        this.chart.axes[0].type = 'number';
        this.chart.axes[1].title.text = this.$t('dataview.chart.frequency');
        
        if (this.chart.series[index].xKey.length > 1) {
          this.chart.series[index].xKey.splice(1);
        }
        
        if (this.chart.series[index].xKey.length > 0) {
          var type = getFieldType(this.chart.series[index].xKey[0], this.schema);
          if (type === 'Macro') {
            type = getMacroType(this.chart.series[index].xKey, this.macros);
          }
          
          if (type !== 'Duration' && type !== 'Progress' && type !== 'Integer' && type !== 'Long' && type !== 'Double' && type !== 'Float') {
            this.chart.series[index].xKey = [];
            this.chart.series[index].xName = null;
          }
        }
      }
      else {
        this.chart.axes[0].type = 'category';
        if (this.chart.series.length > 0 && this.chart.series[0].yNames.length > 0) {
          this.chart.axes[1].title.text = this.chart.series[0].yNames[0];
        }
      }
      
      if (this.chart.series[index].type === 'bar') {
        this.chart.axes[0].position = 'left';
        this.chart.axes[1].position = 'bottom';
      }
      else {
        this.chart.axes[0].position = 'bottom';
        this.chart.axes[1].position = 'left';
      }
      
      if (this.chart.series[index].type !== 'bubble') {
        this.chart.series[index].sizeKey = [];
        this.chart.series[index].sizeName = null;
      }
    },
    changeSizeKey() {
      this.alertSizeMsg = null;
      this.sizeNameEditVal = this.updateName(this.valueText(this.sizeKeyEditVal));
      var type = getFieldType(this.sizeKeyEditVal, this.schema);
      if (type === 'Macro') {
        type = getMacroType(this.sizeKeyEditVal, this.macros);
      }
      
      if (type === 'Duration' && type === 'Progress' && type !== 'Integer' && type !== 'Float' && type !== 'Double' && type !== 'Long' && type !== 'Cost') {
        this.alertSizeMsg = this.$t('dataview.chart.error_numeric_required');
      }
    },
    updateName(key) {
      var myRegexp = /(.+?)\((.+?)\)/g;
      var match = key.includes('.=') ? null : myRegexp.exec(key);
      const fieldlist = match === null ? key.split('.') : match[2].split('.');
      const entity = fieldlist.length > 1 ? fieldlist[fieldlist.length - 2] : fieldlist[0];
      const field = fieldlist[fieldlist.length - 1];
      const words = field.replace(/\s*\(.*?\)\s*/g, '').replace(/^=*/g, "").match(/[A-Z]*[^A-Z]+/g);
      const name = words !== null ? words.join(' ') : field;
      const retVal = `${entity[0] + entity.substr(1).toLowerCase()} ${name[0].toUpperCase()}${name.substr(1)}`;
      if (match === null) {
        return retVal;
      }
      else {
        return `${match[1]}(${retVal})`;
      }
    },
    changeLegendPosition() {
      this.chart.legend.position = this.chartLegendPositionStr == 'null'? null : this.chartLegendPositionStr;
      this.chart.legend.enabled = this.chart.legend.position !== null;
    },
    addAngleKey(index) {
      this.angleNameEditIndex = index;
      this.angleNameEditVal = null;
      this.angleKeyEditVal = null;
      this.angleNameEditShow = true;
      this.chart.series[index].fills.splice(0, 1, this.fills[0]);
      this.chart.series[index].strokes.splice(0, 1, this.strokes[0]);
    },
    editAngleName(index) {
      this.angleNameEditIndex = index;
      if (this.chart.series[index].title == null) {
        this.$set(this.chart.series[index], 'title', { enabled: false, text: null });
      }
      this.angleNameEditVal = this.chart.series[index].title.text;
      this.angleKeyEditVal = this.chart.series[index].angleKeys[0];
      this.angleNameEditShow = true;
    },
    removeAngleKey(index) {
      this.chart.series[index].angleKeys.splice(0, 1);
      this.chart.series[index].title.text = null;
      this.chart.series[index].fills.splice(0, 1);
      this.chart.series[index].strokes.splice(0, 1);
    },
    changeAngleKey() {
      this.angleNameEditVal = this.updateName(this.valueText(this.angleKeyEditVal));
    },
    angleNameEditOk() {
      if (this.chart.series[this.angleNameEditIndex].title == null) {
        this.chart.series[this.angleNameEditIndex].title = {}
      }
      this.chart.series[this.angleNameEditIndex].title.text = this.angleNameEditVal;
      this.chart.series[this.angleNameEditIndex].angleKeys.splice(0, 1, this.angleKeyEditVal);
      this.angleNameEditShow = false;
    },
    getLegendPositionOptionLabel(value) {
      return this.legendPositions.find(i => i.value === value)?.text || value;
    },
    getChartTypeOptionLabel(seriesIndex) {
      return (value) => {
        return this.getChartTypes(seriesIndex).find(i => i.value === value)?.text || value;
      }
    },
    hasScrollbar() {
      const modalBodyElem = document.querySelector('.chart-modal-body')
      return modalBodyElem.scrollHeight > modalBodyElem.clientHeight; //is Vertical Scrollbar enabled
    },
    setupModalBodyHeightChangeObserver() {
      this.$nextTick(() => {
        const modalBodyElem = document.querySelector('.chart-modal-body')
        if (this.observer != null) {
          this.observer.observe(modalBodyElem);
        }
      });
    },
    cleanupModalBodyHeightChangeObserver() {
      if (this.observer != null) {
        this.observer.disconnect();
        this.observer = null;
      }
    }
  }
}
</script>

<style lang="scss">

.current-color {
  display: inline-block;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.colour-picker {
  box-shadow: none !important;
  margin-left: 20px;
}

.chart-axes,
.chart-series {
  min-height: 35px;
}

.x-axis-container,
.y-axis-container {
  position: relative;
  padding: 5px;
  margin: 5px;
}

.size-axis-container,
.axes-container,
.series-container {
  position: relative;
}

.series-remove-btn,
.key-remove-btn {
  position: absolute;
  background: transparent;
  border: none;
  top: 5px;
  right: 5px;
}

.axeskey-remove-btn {
  background: transparent;
  border: none;
}

.name-label {
  padding: 6px;
  margin-right: 5px;
}

.name-edit {
  position: relative;
  top: 1px;
  display: inline-block;
  margin: 1px;
  border: 1px solid var(--border-medium);
  border-radius: 3px;
  cursor: pointer;
}

.y-axis-container .name-edit {
  top: 3px;
}

.axes-container .name-edit {
  margin-top: 5px;
}

.fit-checkbox {
  margin-top: 51px; //35px (the height of chartType selectbox) + 1rem (it's margin bottom spacing)
}
</style>